import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 922.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,922.000000) scale(0.100000,-0.100000)">
          <path d="M3686 6274 c-54 -80 -95 -148 -93 -150 2 -3 92 -3 200 -2 l196 3 -97
147 c-53 81 -100 147 -103 147 -3 1 -50 -65 -103 -145z"/>
<path d="M3710 5970 l0 -80 75 0 75 0 0 80 0 80 -75 0 -75 0 0 -80z"/>
<path d="M3710 5770 l0 -80 75 0 75 0 0 80 0 80 -75 0 -75 0 0 -80z"/>
<path d="M2177 5623 c-4 -3 -7 -489 -7 -1080 l0 -1073 35 0 c29 0 39 6 61 40
15 22 30 40 34 40 4 0 19 -18 34 -41 24 -35 31 -40 59 -37 l32 3 3 775 c1 426
-1 839 -5 918 -5 78 -6 142 -3 142 3 0 21 -31 41 -68 20 -37 77 -130 127 -207
104 -160 463 -715 672 -1040 78 -121 185 -287 239 -370 l97 -150 157 -3 157
-3 0 1081 0 1081 -127 -3 -128 -3 -3 -700 c-1 -385 2 -794 6 -910 4 -115 7
-211 7 -213 -1 -1 -33 49 -72 110 -39 62 -112 178 -163 258 -51 80 -170 267
-265 415 -416 652 -590 924 -628 983 l-40 62 -157 0 c-86 0 -160 -3 -163 -7z"/>
<path d="M4202 4333 c-24 -62 -169 -488 -167 -491 5 -6 610 21 616 27 4 3 -1
31 -11 61 -10 30 -45 138 -78 240 l-60 185 -145 3 -144 3 -11 -28z m178 -283
c17 -69 33 -133 36 -142 5 -16 -3 -18 -65 -18 -84 0 -82 -6 -45 125 14 50 28
107 31 128 3 21 7 36 9 35 1 -2 17 -59 34 -128z"/>
<path d="M5035 4348 c-20 -62 -136 -399 -144 -421 -6 -16 -11 -33 -11 -38 0
-10 134 -12 190 -3 35 6 35 6 59 97 13 51 29 117 35 147 l12 55 28 -115 c16
-63 33 -129 37 -145 l9 -31 102 4 c57 2 108 5 115 7 10 3 -65 255 -123 413
l-16 42 -144 0 c-107 0 -146 -3 -149 -12z"/>
<path d="M5677 4354 c-9 -10 -9 -432 1 -437 4 -2 52 -2 106 1 l99 5 -5 121 -6
121 15 -65 c8 -36 21 -89 29 -117 l15 -53 94 0 c51 0 96 3 98 8 2 4 -19 99
-47 212 l-52 205 -170 3 c-94 1 -173 -1 -177 -4z"/>
<path d="M6265 4348 c-6 -17 -95 -395 -95 -402 0 -3 36 -6 79 -6 67 0 80 3 85
18 2 9 12 51 21 92 29 127 33 129 27 13 l-5 -113 94 0 c153 0 139 -21 139 211
l0 199 -170 0 c-128 0 -172 -3 -175 -12z"/>
<path d="M6900 4178 c-34 -101 -65 -189 -68 -195 -5 -16 203 -19 213 -3 3 6
16 51 29 100 25 97 36 120 36 74 0 -16 9 -60 19 -99 l19 -70 108 4 c60 2 111
5 113 7 1 1 -24 83 -56 181 l-60 178 -144 3 -145 3 -64 -183z"/>
<path d="M7594 4347 c-2 -7 -3 -87 -2 -177 l3 -165 35 1 c104 3 425 16 427 18
4 4 -229 320 -242 328 -5 4 -56 8 -113 8 -75 0 -104 -4 -108 -13z"/>
<path d="M8129 4356 c-2 -2 -3 -78 -1 -168 l3 -163 82 3 c140 4 127 -13 127
172 l0 160 -103 0 c-57 0 -106 -2 -108 -4z"/>
<path d="M8228 3972 c-83 -2 -98 -5 -98 -19 0 -14 -2 -14 -17 -2 -14 11 -45
13 -130 11 -62 -2 -113 -7 -113 -11 0 -6 89 -131 223 -313 l42 -57 103 -1 102
0 0 194 c0 107 -3 196 -7 198 -5 2 -52 2 -105 0z"/>
<path d="M7612 3943 l-22 -4 2 -177 3 -177 108 -3 107 -3 0 186 0 185 -87 -1
c-49 -1 -98 -4 -111 -6z"/>
<path d="M7193 3923 c-13 -2 -23 -11 -23 -19 0 -19 -135 -21 -143 -1 -3 9 -31
11 -112 7 l-108 -5 -53 -150 c-29 -82 -53 -156 -53 -162 -1 -10 30 -13 124
-13 l124 0 10 38 c6 21 14 52 17 70 l7 32 117 0 118 0 6 -32 c3 -18 11 -49 17
-70 l10 -38 125 0 c101 0 125 3 122 14 -2 7 -25 86 -53 174 l-50 162 -90 -1
c-49 -1 -100 -4 -112 -6z"/>
<path d="M6483 3893 l-103 -4 0 -154 0 -155 115 0 115 0 0 160 c0 122 -3 160
-12 158 -7 -1 -59 -4 -115 -5z"/>
<path d="M6152 3858 c-8 -18 -11 -19 -11 -5 -1 15 -12 17 -96 17 -57 0 -95 -4
-95 -10 0 -6 12 -54 26 -108 14 -53 30 -114 35 -134 l10 -38 109 0 109 0 10
38 c17 64 61 247 61 255 0 4 -33 7 -73 7 -67 0 -75 -2 -85 -22z"/>
<path d="M5688 3853 c-16 -4 -18 -19 -18 -139 l0 -134 105 0 105 0 0 140 0
140 -87 -1 c-49 -1 -96 -3 -105 -6z"/>
<path d="M5215 3833 c-22 -2 -112 -7 -200 -11 l-160 -7 -37 -100 c-20 -55 -37
-108 -38 -117 0 -16 12 -18 123 -18 l123 0 18 70 18 70 118 0 117 0 14 -67 14
-68 128 -3 c70 -1 127 1 127 5 0 4 -18 63 -41 130 l-41 123 -121 -2 c-67 -1
-140 -3 -162 -5z"/>
<path d="M4615 3804 c-22 -1 -167 -8 -322 -15 -156 -6 -283 -12 -283 -13 0 -1
-14 -39 -30 -86 -16 -47 -30 -91 -30 -97 0 -10 31 -13 124 -13 l124 0 17 70
17 70 118 0 117 0 14 -67 14 -68 128 -3 c70 -1 127 1 127 5 -1 30 -71 218 -82
219 -7 0 -31 -1 -53 -2z"/>
<path d="M2202 3350 c-51 -76 -95 -146 -98 -154 -5 -14 17 -16 190 -16 108 0
196 4 196 8 0 7 -180 285 -192 298 -3 2 -46 -59 -96 -136z"/>
<path d="M2665 3372 c-1 -2 -1 -12 2 -23 3 -12 14 -19 29 -19 23 0 24 -2 24
-81 l0 -80 31 3 32 3 1 75 c1 74 1 75 29 78 19 2 27 9 27 22 0 16 -10 19 -86
22 -47 2 -87 2 -89 0z"/>
<path d="M2860 3273 l0 -103 65 0 c63 0 65 1 65 25 0 22 -4 25 -36 25 -42 0
-43 23 -1 28 21 3 27 9 27 28 0 20 -5 24 -31 24 -41 0 -33 24 9 28 23 2 32 8
32 22 0 16 -10 19 -65 22 l-65 3 0 -102z"/>
<path d="M4420 3309 c0 -43 -5 -70 -13 -78 -23 -19 -37 9 -37 76 l0 63 -35 0
-35 0 0 -59 c0 -32 5 -72 10 -88 19 -53 95 -70 144 -32 24 19 26 26 26 99 l0
78 -30 4 -30 5 0 -68z"/>
<path d="M5468 3373 l-28 -4 0 -100 0 -99 30 0 30 0 0 105 c0 58 -1 104 -2
104 -2 -1 -15 -4 -30 -6z"/>
<path d="M7238 3373 c-80 -3 -88 -6 -88 -23 0 -16 7 -20 30 -20 l30 0 0 -80 0
-80 30 0 29 0 3 78 c3 76 3 77 31 80 21 3 27 9 27 28 0 13 -1 23 -2 22 -2 -1
-42 -4 -90 -5z"/>
<path d="M3020 3270 l0 -100 65 0 c63 0 65 1 65 25 0 22 -4 25 -35 25 l-35 0
0 75 0 75 -30 0 -30 0 0 -100z"/>
<path d="M3170 3270 l0 -100 71 0 c67 0 70 1 67 23 -2 17 -11 23 -35 25 -40 4
-46 32 -8 32 20 0 25 5 25 25 0 20 -5 25 -25 25 -16 0 -25 6 -25 15 0 10 10
15 30 15 23 0 30 4 30 20 0 18 -7 20 -65 20 l-65 0 0 -100z"/>
<path d="M3380 3363 c-43 -17 -66 -78 -49 -130 22 -68 159 -91 159 -26 0 22
-3 24 -24 19 -39 -10 -76 12 -76 45 0 33 23 49 69 49 26 0 31 4 31 25 0 23 -3
25 -47 24 -27 0 -55 -3 -63 -6z"/>
<path d="M3553 3360 c-24 -10 -53 -58 -53 -89 0 -52 50 -101 105 -101 92 0
139 107 76 171 -31 31 -81 38 -128 19z m86 -62 c16 -45 -22 -94 -53 -69 -19
16 -21 77 -3 84 27 12 48 6 56 -15z"/>
<path d="M3740 3270 l0 -100 25 0 c24 0 25 2 26 68 1 70 3 68 28 -26 11 -40
14 -43 44 -40 29 3 32 7 48 68 l17 65 1 -67 1 -68 30 0 30 0 0 100 0 100 -45
0 -44 0 -18 -61 -18 -61 -14 61 -13 61 -49 0 -49 0 0 -100z"/>
<path d="M4020 3270 l0 -100 30 0 c30 0 30 0 31 58 l1 57 15 -57 c14 -55 17
-58 47 -58 31 0 33 2 48 63 l16 62 1 -62 1 -63 35 0 35 0 0 100 0 100 -50 0
c-46 0 -50 -2 -56 -27 -22 -99 -23 -99 -44 -15 l-11 42 -49 0 -50 0 0 -100z"/>
<path d="M4520 3269 l0 -100 28 3 c25 3 27 7 32 55 l5 52 39 -55 c32 -43 45
-54 68 -54 l28 0 0 100 0 100 -29 0 c-28 0 -30 -2 -33 -47 l-3 -48 -35 47
c-29 39 -41 47 -67 48 l-33 0 0 -101z"/>
<path d="M4760 3270 l0 -100 30 0 30 0 0 100 0 100 -30 0 -30 0 0 -100z"/>
<path d="M4893 3358 c-23 -11 -53 -64 -53 -93 0 -56 57 -99 126 -93 35 3 39 6
42 32 3 26 2 28 -23 22 -36 -9 -75 16 -75 49 0 28 26 45 69 45 26 0 31 4 31
25 0 23 -3 25 -47 24 -27 0 -58 -5 -70 -11z"/>
<path d="M5074 3323 c-9 -26 -23 -63 -31 -82 -21 -53 -17 -71 15 -71 16 0 32
8 40 20 7 11 21 20 32 20 10 0 24 -9 30 -20 7 -13 21 -20 41 -20 29 0 30 2 24
31 -4 18 -18 62 -30 98 -23 65 -24 66 -63 69 -39 3 -40 2 -58 -45z m66 -49 c0
-15 -6 -24 -15 -24 -17 0 -18 4 -9 37 8 29 24 20 24 -13z"/>
<path d="M5240 3350 c0 -16 7 -20 30 -20 l30 0 0 -80 0 -80 30 0 29 0 3 78 c3
76 3 77 31 80 19 2 27 9 27 23 0 17 -8 19 -90 19 -83 0 -90 -1 -90 -20z"/>
<path d="M5559 3341 c-66 -66 -16 -171 81 -171 23 0 42 10 67 35 32 32 35 39
30 78 -8 57 -43 87 -103 87 -37 0 -51 -6 -75 -29z m99 -30 c19 -12 11 -72 -11
-85 -15 -9 -22 -7 -38 13 -23 28 -24 45 -3 65 17 18 32 20 52 7z"/>
<path d="M5760 3270 l0 -100 30 0 c28 0 29 1 32 56 l3 56 40 -56 c35 -49 44
-56 70 -54 l30 3 2 98 1 97 -33 0 c-33 0 -34 -1 -37 -46 l-3 -46 -34 46 c-29
39 -40 46 -68 46 l-33 0 0 -100z"/>
<path d="M6006 3357 c-12 -9 -17 -23 -14 -47 2 -28 9 -38 35 -50 46 -21 44
-44 -3 -37 -34 6 -35 5 -32 -21 3 -25 6 -27 54 -27 60 1 84 16 84 55 0 28 -14
45 -52 65 -28 14 -19 31 15 27 23 -2 27 2 27 23 0 23 -3 25 -48 25 -27 0 -56
-6 -66 -13z"/>
<path d="M6270 3355 c-7 -9 -10 -27 -7 -44 4 -21 1 -34 -13 -46 -27 -25 -25
-61 4 -80 19 -13 39 -15 100 -9 73 6 76 8 60 25 -12 13 -13 22 -5 38 17 31 13
41 -14 41 -16 0 -25 -6 -25 -16 0 -14 -3 -14 -15 -4 -13 11 -12 15 10 36 30
28 31 36 7 57 -23 21 -85 22 -102 2z m56 -42 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z m-2 -103 c-16 -16 -49 13 -40 35 6 18 8 17 29 -3 14
-15 18 -25 11 -32z"/>
<path d="M6540 3269 l0 -101 65 4 c86 5 118 33 118 104 0 39 -4 51 -27 71 -22
19 -38 23 -92 23 l-64 0 0 -101z m104 45 c32 -31 15 -94 -26 -94 -15 0 -18 9
-18 55 0 59 13 70 44 39z"/>
<path d="M6758 3270 l3 -100 30 0 29 0 0 100 0 100 -32 0 -33 0 3 -100z"/>
<path d="M6893 3358 c-53 -26 -68 -122 -24 -162 23 -21 107 -31 144 -17 24 10
27 15 27 61 l0 50 -55 0 c-48 0 -55 -2 -55 -20 0 -13 7 -20 19 -20 11 0 22 -4
26 -9 9 -16 -12 -22 -39 -11 -20 7 -26 16 -26 39 0 37 23 51 79 51 37 0 41 2
41 25 0 24 -3 25 -57 24 -32 0 -68 -5 -80 -11z"/>
<path d="M7060 3270 l0 -101 33 3 32 3 0 95 0 95 -32 3 -33 3 0 -101z"/>
<path d="M7371 3284 c-17 -47 -31 -92 -31 -100 0 -22 53 -17 68 6 7 11 21 20
32 20 10 0 24 -9 30 -20 10 -19 69 -30 70 -12 1 4 -12 49 -27 99 l-28 93 -41
0 -41 0 -32 -86z m81 -9 c2 -11 -3 -20 -9 -20 -7 0 -13 3 -13 8 0 4 0 17 0 29
0 26 17 13 22 -17z"/>
<path d="M7560 3270 l0 -100 71 0 c67 0 70 1 67 23 -2 17 -11 23 -35 25 l-33
3 0 75 0 74 -35 0 -35 0 0 -100z"/>
<path d="M7818 3356 c-11 -8 -18 -27 -18 -45 0 -26 7 -36 35 -52 19 -12 35
-26 35 -31 0 -7 -14 -8 -35 -5 -32 5 -35 4 -35 -18 0 -29 32 -41 85 -31 66 13
72 78 9 115 -41 24 -37 45 8 36 27 -6 29 -5 26 17 -3 20 -9 23 -48 25 -26 2
-52 -3 -62 -11z"/>
<path d="M7960 3351 c0 -14 8 -21 28 -23 27 -3 27 -4 28 -78 l1 -75 32 -3 31
-3 0 80 0 81 30 0 c23 0 30 4 30 20 0 19 -7 20 -90 20 -82 0 -90 -2 -90 -19z"/>
<path d="M8183 3359 c-31 -16 -53 -75 -42 -113 15 -51 43 -71 100 -71 43 0 54
4 75 28 30 35 33 104 6 137 -21 26 -103 37 -139 19z m85 -51 c19 -19 15 -66
-7 -78 -25 -13 -27 -13 -45 6 -30 29 -14 84 24 84 9 0 21 -5 28 -12z"/>
<path d="M8370 3270 l0 -100 35 0 c34 0 35 1 35 36 0 30 2 34 14 25 8 -7 17
-23 20 -36 5 -21 13 -25 42 -25 32 0 35 2 29 23 -4 12 -14 33 -22 45 -13 21
-13 26 1 41 20 22 20 55 0 75 -11 12 -35 16 -85 16 l-69 0 0 -100z m105 40 c0
-8 -8 -16 -17 -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z"/>
<path d="M8570 3270 l0 -100 65 0 c63 0 65 1 65 25 0 22 -4 25 -35 25 -24 0
-35 5 -35 15 0 10 10 15 30 15 25 0 30 4 30 25 0 21 -5 25 -31 25 -41 0 -33
24 9 28 24 2 32 8 32 23 0 17 -7 19 -65 19 l-65 0 0 -100z"/>
<path d="M2220 3070 l0 -81 78 3 c66 3 77 5 76 21 -1 9 -2 44 -3 77 l-1 60
-75 0 -75 0 0 -80z"/>
<path d="M2220 2870 l0 -81 77 3 76 3 0 75 0 75 -76 3 -77 3 0 -81z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
